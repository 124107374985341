import { FunctionComponent } from 'react';
import classNames from 'classnames';

import LayoutListContainer from 'components/layout/ListContainer/component';
import LayoutListElement from 'components/layout/ListElement/component';
import { IconTypes } from 'components/layout/ListElement/types';

import StyledComponent from './styles';
import { Props, TileTypes } from './types';

const ContentBlocksTile: FunctionComponent<Props> = ({ listElements, style }) => {
    const title = listElements?.filter((element) => element.type === TileTypes.Title)[0]?.name;
    const price = listElements?.filter((element) => element.type === TileTypes.Price)[0]?.name;
    const subPrice = listElements?.filter((element) => element.type === TileTypes.SubPrice)[0]?.name;
    const list = listElements?.filter((element) =>
        element.type.includes(TileTypes.IconBlue) || element.type.includes(TileTypes.IconGreen)
    );

    return (
        <StyledComponent className={classNames('content-blocks-tile', `style-${style}`)}>
            <header className="tile-header">
                <h2 className="tile-header-title">{title}</h2>
            </header>
            <LayoutListContainer>
                {Array.isArray(list) && list.map((element) => (
                    <LayoutListElement
                        key={element.name}
                        text={element.name}
                        type={element.type as IconTypes}
                        active={Boolean(element.active)}
                    />
                ))}
            </LayoutListContainer>
            <div className="tile-price">
                <p dangerouslySetInnerHTML={{ __html: price }} />
                {subPrice && (
                    <p dangerouslySetInnerHTML={{ __html: subPrice }} />
                )}
            </div>
        </StyledComponent>
    );
};

export default ContentBlocksTile;