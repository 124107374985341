import { FunctionComponent } from 'react';

import { Props } from './index';
import StyledComponent from './styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LayoutSlickButton: FunctionComponent<Props> = ({ currentSlide, slideCount, children, ...props }) => (
    <StyledComponent className='layout-slider-button'>
        <span
            className='layout-slider-button-arrow'
            {...props}
        >
            {children}
        </span>
    </StyledComponent>
);

export default LayoutSlickButton;