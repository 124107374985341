const translations = {
    title: {
        type: 'text',
        defaultValue: '<span class="text-green">Wszystko</span> czego potrzebujesz <br/> w <span class="text-green">jednym miejscu</span>',
    },
    description: {
        type: 'richText',
        defaultValue: 'Nowoczesne, wygodne i przydatne narzędzie do zarządzania transportem odpadów!',
    },
    button: {
        type: 'text',
        defaultValue: 'Skontaktuj się z nami!',
    },
};

export default translations;
