import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
	position: relative;
	width: 100%;
	height: 100%;
	margin: 4em 0;

	.platform-container {
		min-height: 40em;
		width: 100%;

		.platform-mobile-photo-link {
			pointer-events: none;
		}
	}

	@media all and (max-width: ${vars.tabletL}) {
		.platform-container {
			min-height: unset;

			.platform-mobile-photo-link {
				pointer-events: auto;
			}
		}
	}
`;
