import { FunctionComponent } from 'react';

import HeroSection from 'components/content/sections/HeroSlider';

import StyledComponent from './styles';
import { Props } from './types';

const PageHomeSectionHero: FunctionComponent<Props> = ({ translations }) => {
    return (
        <StyledComponent className="page-home-section-hero">
            <HeroSection
                title={translations?.title}
                description={translations?.description}
                buttonContent={translations?.button}
            />
        </StyledComponent>
    );
};

export default PageHomeSectionHero;