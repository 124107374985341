const translations = {
    title: {
        type: 'text',
        defaultValue: 'Platforma',
    },
    description: {
        type: 'text',
        defaultValue: 'Przyjemny wizualnie układ graficzny',
    },
};

export default translations;
