import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.li`
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 1em;

	.list-element-text {
		font-size: 0.9em;
		font-weight: 400;
		max-width: 90%;

		&.disabled {
			opacity: 0.4;
		}
	}

	@media all and (max-width: ${vars.tabletL}) {
		.list-element-text {
			font-size: 1.1em;
		}
	}
`;
