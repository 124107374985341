import Component from './component';

Component.defaultProps = {
    children: null,
    label: null,
    error: null,
    disabled: false,
    decoratorLeft: null,
    decoratorRight: null,
};

export default Component;