import Component from './component';
import { TextareaStyles } from './types';

Component.defaultProps = {
    className: null,
    placeholder: null,
    value: '',
    style: TextareaStyles.Primary,
    styles: {},
    error: null,
    disabled: false,
    decoratorLeft: null,
    decoratorRight: null,
};

export default Component;