export enum HeaderStyles {
    Primary = 'primary',
    Hero = 'hero',
    Dark = 'dark',
    Light = 'light',
}

export enum HeaderTextPositions {
    Center = 'center',
    Left = 'left',
}

export interface Props {
    className?: string;
    title?: string;
    subTitle?: string;
    description?: string;
    isPrimaryTitle?: boolean;
    children?: any;

    style?: HeaderStyles,
    textPosition?: HeaderTextPositions,
}
