import { FunctionComponent  } from 'react';

import StyledComponent from './styles';
import { Props } from './types';

const ContentSectionsFormResultInfo: FunctionComponent<Props> = ({ title, description, icon, children }) => {
    return (
        <StyledComponent className="content-section-form-result-info">
            { title && (
                <header className="result-header">
                    { icon && (
                        <div className="result-header-icon">
                            {icon}
                        </div>
                    )}
                    <h2 className="result-header-title">{title}</h2>
                </header>
            )}
            { description && (
                <p className="result-description">
                    {description}
                </p>
            )}
            { children }
        </StyledComponent>
    );
};

export default ContentSectionsFormResultInfo;