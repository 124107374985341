import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	text-align: left;
	position: relative;
	display: block;
	width: 100%;
	font-size: 0.9em;
	color: ${vars.colorTextDark};
	line-height: 80%;
	font-weight: 500;

	@media all and (max-width: ${vars.mobileL}) {
		font-size: 1em;
	}
`;
