import { FunctionComponent } from 'react';

import Image from 'components/layout/Image';

import StyledComponent from './styles';
import { Props } from './types';

const ContentBlocksGraphElement: FunctionComponent<Props> = ({ name, description, image }) => {
    return (
        <StyledComponent className="content-blocks-graph-element">
            <Image
                src={image}
                layout="fixed"
                width={50}
                height={140}
            />
            <div className="graph-element-content">
                <h3 className="grap-element-name">{name}</h3>
                <p className="grap-element-description">{description}</p>
            </div>
        </StyledComponent>
    );
};

export default ContentBlocksGraphElement;