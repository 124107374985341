import metadata from './meta/metadata';
import contact from './sections/contact';
import hero from './sections/hero';
import modal from './sections/modal';
import platform from './sections/platform';
import pricingPlans from './sections/pricingPlans';
import solucion from './sections/solucion';

const translations = {
    hero,
    solucion,
    pricingPlans,
    platform,
    contact,
    modal,
};

export const meta = {
    ...metadata,
};

export default translations;