import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
	position: relative;
	width: 100%;
	min-height: 30em;
	overflow: hidden;
	background-color: ${vars.colorPrimaryLight};
	padding: 4em 0;

	.contact-cols-wrapper {
		padding: 5em 0;
		display: flex;
		justify-content: space-around;
		gap: 2em;

		.col-left {
			.col-left-headline {
				font-weight: 500;
				font-size: 2.6em;
				letter-spacing: 1px;
				line-height: 1.5em;

				.text-green {
					font-weight: 500;
					color: ${vars.colorPrimaryGreen};
				}
			}
		}

		.col-right {
			min-width: 40%;
			max-width: 40%;
			min-height: 37em;
		}
	}

	@media all and (max-width: ${vars.mobileL}) {
		.contact-cols-wrapper {
			flex-flow: column wrap;
			padding: 0 1em;

			.col-left {
				.col-left-headline {
					font-size: 2em;
					padding: 1em 0;
					text-align: center;
				}
			}
		}
	}
`;
