const translations = {
    title: {
        type: 'text',
        defaultValue: 'Kontakt',
    },
    leftInfo: {
        type: 'text',
        defaultValue: 'Skontaktuj się z nami <br/>aby poznać aplikację!<br/> <span class="text-green">Odezwiemy się!</span>',
    },
    inputUserName: {
        type: 'text',
        defaultValue: 'Imię i Naziwsko',
    },
    inputEmail: {
        type: 'text',
        defaultValue: 'Adres e-mail',
    },
    inputPhone: {
        type: 'text',
        defaultValue: 'Numer telefonu',
    },
    inputMessage: {
        type: 'text',
        defaultValue: 'Wiadomość',
    },
    placeholderMessage: {
        type: 'text',
        defaultValue: 'Napisz w czym możemy Ci pomóc',
    },
    submitForm: {
        type: 'text',
        defaultValue: 'Wyślij',
    },
    resultFormTitle: {
        type: 'text',
        defaultValue: 'Dziękujemy za wiadomość!',
    },
    resultFormDescription: {
        type: 'text',
        defaultValue: 'Postaramy się skontaktować z Tobą najszybciej jak to możliwe.',
    },
    rodo: {
        type: 'text',
        defaultValue: 'Administratorem Twoich danych osobowych jest Gruzilla sp. z o.o. z siedzibą w Lublinie (20-027), ul. Sądowa 2/11. Twoje dane osobowe będą przetwarzane w celu odpowiedzi na Twoją wiadomość oraz archiwizacji formularza. Więcej informacji znajdziesz w naszym <a class=\'link\' href=\'/regulamin.pdf\'>Regulaminie</a> i <a class=\'link\' href=\'/polityka-prywatnosci.pdf\'>Polityce Prywatności</a>. ',
    },
};

export default translations;
