import { FunctionComponent } from 'react';
import Slick from 'react-slick';
import classnames from 'classnames';

import arrowLeft from 'theme/icons/arrow-left.svg';
import arrowRight from 'theme/icons/arrow-right.svg';

import Image from 'components/layout/Image';
import SliderButton from 'components/layout/SliderButton';

import { Props } from './index';
import StyledComponent from './styles';

const LayoutSlider: FunctionComponent<Props> = ({ children, sliderProps }) => {
    return (
        <StyledComponent className={classnames('layout-slider')}>
            <Slick
                adaptiveHeight={false}
                accessibility={true}
                arrows={true}
                dots={true}
                infinite={(children as React.ReactNode[]).length > 1 ? true : false}
                speed={500}
                {...sliderProps}
                nextArrow={(
                    <SliderButton>
                        <Image
                            className="arrow-right"
                            src={arrowRight}
                            aria-label="Przesuń w prawo"
                            loading="lazy"
                        />
                    </SliderButton>
                )}
                prevArrow={(
                    <SliderButton>
                        <Image
                            className="arrow-left"
                            src={arrowLeft}
                            aria-label="Przesuń w lewo"
                            loading="lazy"
                        />
                    </SliderButton>
                )}
                customPaging={(index) => (
                    <div
                        className="slider-dot"
                        aria-label={`Strona ${index}`}
                    />
                )}
            >
                {children}
            </Slick>
        </StyledComponent>
    );
};

export default LayoutSlider;