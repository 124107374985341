import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: .5em;
    position: relative;

    .layout-forms-input-error {
        position: absolute;
        top: 100%;
    }

    .label-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: .3em;
    }

    .internal-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: stretch;
        position: relative;
        border-radius: .3em;

        .input-decorator {
            position: absolute;
            top: 0;
            z-index: 2;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 .5em;
            font-weight: 600;

            a, button {
                cursor: pointer;
            }

            &.input-decorator-left {
                left: 0;
                border-top-left-radius: .5em;
                border-bottom-left-radius: .5em;
            }

            &.input-decorator-right {
                right: 0;
                border-top-right-radius: .5em;
                border-bottom-right-radius: .5em;
                padding-right: .1em;
            }
        }
        .input-body {
            width: 100%;

            &.left-decorator-error {
                padding-left: 1.5em;
            }
        }
    }

    &.error {
        .internal-wrapper {
            border-radius: .5em  !important;
            border: .1em solid ${vars.colorError} !important;
        }

        .error-info {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            margin: 0.5em 0;
            font-size: 0.9em;
            color: ${vars.colorError};
        }
    }


    @media all and (max-width: ${vars.mobileL}) {
        margin: 0 0 1em 0;

        &.error {
            .error-info {
                font-size: 1.1em;
            }
        }
    }
`;
