export enum Endpoints {
    /**
     * Public module
     */


    // Contact
    PublicContactForm = '/contacts/submit',

}

export interface ListParamsDefault {
    page?: number;
    perPage?: number;
    search?: string;
}