import styled from '@emotion/styled';

export default styled.ul`
	position: relative;
	margin: 1.5em 0 !important;
	display: flex;
	flex-flow: column wrap;
	place-content: center;
	gap: 1.5em;
`;
