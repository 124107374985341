import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
	position: relative;
	width: 100%;
	height: 100%;
	margin: 4em 0;

	.tiles-container {
		display: flex;
		place-content: center;
		gap: 6em;
	}

	.tiles-mobile {
		display: none;
	}

	@media all and (max-width: ${vars.tabletL}) {
		.tiles-desktop {
			display: none;
		}

		.tiles-mobile {
			display: block;
		}
	}
`;
