import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
	position: relative;
	width: 100%;
	min-height: 30em;
	overflow: hidden;
	background-color: ${vars.colorPrimaryLight};
	padding: 4em 0 0 0;

	.platform {
		margin: 0 auto 4em auto;
	}

	.platform-slider-wrapper {
		padding: 0.5em 0 4em 0;
		background-color: ${vars.colorPrimaryDark};
	}
`;
