import { FunctionComponent } from 'react';
import Link from 'next/link';

import SlideImage1 from 'theme/images/platform/slide-platform-1.png';
import SlideImage2 from 'theme/images/platform/slide-platform-2.png';
import SlideImage3 from 'theme/images/platform/slide-platform-3.png';

import Image from 'components/layout/Image';
import Slider from 'components/layout/Slider';

import StyledComponent from './styles';
import { Props } from './types';

const ContentSectionsPlatformSlider: FunctionComponent<Props> = () => {
    const settings = {
        arrows: true,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const slides = [SlideImage1, SlideImage2, SlideImage3];

    return (
        <StyledComponent className="content-sections-platform-slider">
            <div className="platform-container">
                <Slider
                    sliderProps={settings}
                >
                    {Array.isArray(slides) && slides.map((slide, idx) => (
                        <div
                            key={idx}
                            className="platform-mobile-photo-link"
                        >
                            <Link
                                href={slide?.src}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Image
                                    src={slide}
                                    layout='responsive'
                                    loading='eager'
                                />
                            </Link>
                        </div>
                    ))}
                </Slider>
            </div>
        </StyledComponent>
    );
};

export default ContentSectionsPlatformSlider;