import qs from 'qs';

export function parseToQueryString(url: string, params = {}): string {
    const urlParts = url.split('?');
    const urlPath = urlParts[0];
    const urlParams = typeof urlParts[1] !== 'undefined' ? qs.parse(urlParts[1]) : {};

    params = {
        ...urlParams,
        ...params,
    };
    return `${urlPath}?${qs.stringify(params)}`
        .replace(/\?+$/, ''); // Replace all "?" chars at the of string
}
