import { FunctionComponent } from 'react';

import PricingPlanTiles from 'components/content/sections/PricingPlanTiles';
import Header from 'components/layout/Header';
import { HeaderStyles, HeaderTextPositions } from 'components/layout/Header/types';
import LayoutContainer from 'components/layout/LayoutContainer/component';

import StyledComponent from './styles';
import { Props } from './types';

const PageHomeSectionPricingPlans: FunctionComponent<Props> = ({ translations }) => {
    return (
        <StyledComponent
            className="page-home-section-pricing-plans"
            id="pricingplans"
        >
            <LayoutContainer className="pricing-plans">
                <Header
                    className="header-pricing-plans"
                    title={translations?.title}
                    description={translations?.description}
                    style={HeaderStyles.Light}
                    textPosition={HeaderTextPositions.Center}
                />
                <PricingPlanTiles
                    pricingPlanLight={translations?.pricingPlanLight}
                    pricingPlanStandard={translations?.pricingPlanStandard}
                />
            </LayoutContainer>
        </StyledComponent>
    );
};

export default PageHomeSectionPricingPlans;