import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	display: flex;
	flex-flow: column wrap;
	width: 100%;
	padding: 1em 1em 2em 1em;
	background-color: ${vars.colorPrimaryWhite};
	border-radius: ${vars.defaultRadius};
	max-width: 28%;

	.tile-header {
		text-align: center;
		border-bottom: 0.05em solid ${vars.colorSecondaryGray};
		margin: 0 3em;
		padding: 1em 0;

		.tile-header-title {
			font-weight: 600;
			font-size: 1.4em;
		}
	}

	.tile-price {
		border-top: 0.07em solid ${vars.colorSecondaryGray};
		border-bottom: 0.07em solid ${vars.colorSecondaryGray};
		margin: 0 1em;
		padding: 1em 0;
		text-align: center;
		font-size: 1.2em;
		display: flex;
		flex-flow: column wrap;
		place-content: center;
		gap: 1em;
		min-height: 8em;

		.price-value {
			margin: 0 0.1em 0 0;
			font-size: 2em;
			font-weight: 600;
		}
	}

	&.style-light {
		border-top: 1.5em solid ${vars.colorPrimaryGray};
	}
	&.style-standard {
		border-top: 1.5em solid ${vars.colorPrimaryGreen};
	}
	&.style-premium {
		border-top: 1.5em solid ${vars.colorPrimaryBlue};
	}

	@media all and (max-width: ${vars.tabletL}) {
		max-width: 100%;
		width: 90%;
		margin: 0 auto;
	}
`;
