import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
	width: 100%;

	.result-header {
		display: flex;
		place-items: center;
		gap: 0.5em;

		.result-header-icon {
			margin: 0.3em 0 0 0;
		}

		.result-header-title {
			color: ${vars.colorPrimaryGreen};
			font-weight: 600;
			font-size: 2em;
		}
	}
	.result-description {
		line-height: 1.8em;
		font-size: 1em;
	}

	@media all and (max-width: ${vars.mobileL}) {
		.result-header {
			justify-content: center;
		}
		.result-description {
			font-size: 1.2em;
			margin: 1em 0;
			text-align: center;
		}
	}
`;
