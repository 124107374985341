const translations = {
    title: {
        type: 'text',
        defaultValue: 'Pakiety',
    },
    description: {
        type: 'rich text',
        defaultValue: 'Wybierz pakiet odpowiadający Twoim potrzebom.',
    },
    pricingPlanLight: {
        type: 'array',
        arraySchema: {
            name: { type: 'text' },
            type: { type: 'text' },
            active: { type: 'boolean' },
        },
        defaultValue: [{
            name: 'Pakiet Light',
            type: 'title',
        }, {
            name: 'Ewidencja Klientów',
            type: 'iconBlue',
            active: true,
        }, {
            name: 'Ewidencja Pracowników',
            type: 'iconBlue',
            active: true,
        }, {
            name: 'Ewidencja Kontenerów',
            type: 'iconBlue',
            active: true,
        }, {
            name: 'Zarządzanie samochodami',
            type: 'iconBlue',
            active: true,
        }, {
            name: 'Przyjmowanie Zleceń',
            type: 'iconBlue',
            active: true,
        }, {
            name: 'Planowanie Tras',
            type: 'iconBlue',
            active: true,
        }, {
            name: 'Proste raportowanie',
            type: 'iconBlue',
            active: true,
        }, {
            name: 'Aplikacja dla kierowców',
            type: 'iconGreen',
            active: false,
        }, {
            name: 'Połączenie z fakturownią',
            type: 'iconGreen',
            active: false,
        }, {
            name: 'Wiele instancji',
            type: 'iconGreen',
            active: false,
        }, {
            name: 'Zaawansowany moduł raportów',
            type: 'iconGreen',
            active: false,
        }, {
            name: 'Połączenie z BDO',
            type: 'iconGreen',
            active: false,
        }, {
            name: '<span class="price-value">300</span> zł/miesiąc',
            type: 'price',
        }],
    },
    pricingPlanStandard: {
        type: 'array',
        arraySchema: {
            name: { type: 'text' },
            type: { type: 'text' },
            active: { type: 'boolean' },
        },
        defaultValue: [{
            name: 'Pakiet Standard',
            type: 'title',
        }, {
            name: 'Ewidencja Klientów',
            type: 'iconBlue',
            active: true,
        }, {
            name: 'Ewidencja Pracowników',
            type: 'iconBlue',
            active: true,
        }, {
            name: 'Ewidencja Kontenerów',
            type: 'iconBlue',
            active: true,
        }, {
            name: 'Zarządzanie samochodami',
            type: 'iconBlue',
            active: true,
        }, {
            name: 'Przyjmowanie Zleceń',
            type: 'iconBlue',
            active: true,
        }, {
            name: 'Planowanie Tras',
            type: 'iconBlue',
            active: true,
        }, {
            name: 'Proste raportowanie',
            type: 'iconBlue',
            active: true,
        }, {
            name: 'Aplikacja dla kierowców',
            type: 'iconGreen',
            active: true,
        }, {
            name: 'Połączenie z fakturownią',
            type: 'iconGreen',
            active: true,
        }, {
            name: 'Wiele instancji',
            type: 'iconGreen',
            active: false,
        }, {
            name: 'Zaawansowany moduł raportów',
            type: 'iconGreen',
            active: false,
        }, {
            name: 'Połączenie z BDO',
            type: 'iconGreen',
            active: false,
        }, {
            name: '<span class="price-value">100</span> zł/miesiąc',
            type: 'price',
        },  {
            name: 'za każdy samochód',
            type: 'subPrice',
        }],
    },
};

export default translations;
