// import ApiPage from 'types/api/Page';
// import { ApiResources } from 'types/apiResources';
import { Endpoints } from 'types/endpoints';

import Page from 'models/Page';

import { withVariables } from 'utils/string';
import { request } from 'services/Api';

export interface CreateParams {
    userName: string,
    email: string,
    phone: string,
    content: string,
}
export const create = async (params: CreateParams): Promise<Page> => {
    const response = await request({
        method: 'POST',
        path: withVariables(Endpoints.PublicContactForm, params),
        requestParams: params,
    });

    // const data = response?.payload?.data;
    // const resource: ApiPage = data && data[ApiResources.Page];

    // if(!data || !resource) {
    //     return null;
    // }

    return response;
};
