import { FunctionComponent } from 'react';
import { useRouter } from 'next/router';

import { Routes } from 'types/routes';

import MacbookImage from 'theme/images/hero/macbook-hero.png';

import Button from 'components/layout/Button';
import Header from 'components/layout/Header';
import { HeaderStyles } from 'components/layout/Header/types';
import Image from 'components/layout/Image';
import LayoutContainer from 'components/layout/LayoutContainer';

import StyledComponent from './styles';
import { Props } from './types';

const ContentSectionsHero: FunctionComponent<Props> = ({ title, description, buttonContent }) => {
    const router = useRouter();

    return (
        <StyledComponent className="content-section-hero">
            <LayoutContainer className="hero-wrapper">
                <div className="image-container">
                    <Image
                        src={MacbookImage}
                        layout="responsive"
                    />
                </div>
                <Header
                    className='header'
                    title={title}
                    description={description}
                    isPrimaryTitle={true}
                    style={HeaderStyles.Hero}
                >
                    <Button
                        className="header-button"
                        onClick={() => router.push(Routes.PublicHomeContact)}
                    >
                        {buttonContent}
                    </Button>
                </Header>
            </LayoutContainer>
        </StyledComponent>
    );
};

export default ContentSectionsHero;