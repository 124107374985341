import { FunctionComponent } from 'react';

import Tile from 'components/content/blocks/Tile';
import Slider from 'components/layout/Slider';

import StyledComponent from './styles';
import { Props } from './types';


const ContentSectionsTiles: FunctionComponent<Props> = ({ pricingPlanLight, pricingPlanStandard, pricingPlanPremium }) => {
    const settingsMobileSlider = {
        arrows: true,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <StyledComponent className="content-sections-contact-titles">
            <div className="tiles-container tiles-desktop">
                { pricingPlanLight && (
                    <Tile
                        style="light"
                        listElements={pricingPlanLight}
                    />
                )}
                { pricingPlanStandard && (
                    <Tile
                        style="standard"
                        listElements={pricingPlanStandard}
                    />
                )}
                { pricingPlanPremium && (
                    <Tile
                        style="premium"
                        listElements={pricingPlanPremium}
                    />
                )}
            </div>
            <div className="tiles-container tiles-mobile">
                <Slider sliderProps={settingsMobileSlider}>
                    { pricingPlanLight && (
                        <Tile
                            style="light"
                            listElements={pricingPlanLight}
                        />
                    )}
                    { pricingPlanStandard && (
                        <Tile
                            style="standard"
                            listElements={pricingPlanStandard}
                        />
                    )}
                    { pricingPlanPremium && (
                        <Tile
                            style="premium"
                            listElements={pricingPlanPremium}
                        />
                    )}
                </Slider>

            </div>
        </StyledComponent>
    );
};

export default ContentSectionsTiles;