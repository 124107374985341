import { FunctionComponent } from 'react';
import classnames from 'classnames';

import BlueCheckIcon from 'theme/icons/check.svg';
import GreenCheckIcon from 'theme/icons/check-green.svg';
import DisabledIcon from 'theme/icons/x.svg';

import Image from 'components/layout/Image';

import StyledComponent from './styles';
import { IconTypes, Props } from './types';

const LayoutListElement: FunctionComponent<Props> = ({ text, className, type, active }) => {
    const getIcon = (): string => {
        if (!active) return DisabledIcon;

        switch (type) {
            case IconTypes.IconBlue:
                return BlueCheckIcon;
            case IconTypes.IconGreen:
                return GreenCheckIcon;
            default:
                return BlueCheckIcon;
        }
    };

    return (
        <StyledComponent className={classnames('layout-list-element', className)}>
            <Image
                src={getIcon()}
                width={20}
                height={20}
                layout='fixed'
            />
            <span className={classnames('list-element-text', {
                'disabled': Boolean(!active),
            })}
            >
                {text}
            </span>
        </StyledComponent>
    );
};

export default LayoutListElement;