import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	.button {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;

		border-radius: 0.25em;
		border: none;
		cursor: pointer;
	}

	span {
		font-size: 1em;
		text-transform: uppercase;
	}

	/* Layouts */
	&.layout-default {
		.button {
			padding: 0.9em 1.8em;
		}
	}
	&.layout-wide {
		.button {
			padding: 1em 2.6em;
		}
	}
	&.layout-block {
		width: 100%;

		.button {
			padding: 0.7em 1em;
			width: 100%;
		}

		a {
			width: 100%;
		}
	}

	/* Sizes */
	&.size-small {
		.button {
			font-size: 0.75em;
		}
	}
	&.size-medium {
		.button {
			font-size: 0.9em;
		}
	}
	&.size-large {
		.button {
			font-size: 1.15em;
		}
	}

	/* Styles */
	&.style-primary {
		.button {
			transition: 0.4s ease-in-out color, background-color;
		}

		&.variant-primary {
			.button {
				background-color: ${vars.colorPrimaryGreen};
				color: ${vars.colorTextWhite};
				border: 0.1em solid ${vars.colorPrimaryGreen};
				transition: 0.4s ease-in-out color, 0.2s ease-in-out background-color;

				.button-content {
					font-weight: 700;
				}

				&:hover {
					background-color: ${vars.colorPrimaryWhite};
					color: ${vars.colorPrimaryGreen};
				}
			}
		}
		&.variant-outline {
			.button {
				background-color: transparent;
				color: ${vars.colorPrimaryLight};
				border: 0.1em solid ${vars.colorPrimaryLight};
				transition: 0.4s ease-in-out color, background-color;

				.button-content {
					font-weight: 700;
				}

				&:hover {
					background-color: ${vars.colorPrimaryLight};
					color: ${vars.colorPrimaryGreen};
				}
			}
		}
	}

	&.style-secondary {
		.button {
			transition: 0.4s ease-in-out color, background-color;
		}

		&.variant-primary {
			.button {
				background-color: ${vars.colorSecondaryBlue};
				color: #ffffff;
				border: 0.1em solid ${vars.colorSecondaryBlue};
				transition: 0.4s ease-in-out color, 0.2s ease-in-out background-color;

				.button-content {
					font-weight: 700;
				}

				&:hover {
					background-color: ${vars.colorPrimaryLight};
					color: ${vars.colorSecondaryBlue};
				}
			}
		}
		&.variant-outline {
			.button {
				background-color: transparent;
				color: ${vars.colorTextWhite};
				border: 0.1em solid ${vars.colorSecondaryBlue};
				transition: 0.4s ease-in-out color, background-color;

				.button-content {
					font-weight: 700;
				}

				&:hover {
					background-color: ${vars.colorSecondaryBlue};
					color: #ffffff;
				}
			}
		}
	}

	/* Modificators */
	&.disabled {
		.button {
			background-color: ${vars.colorDisabled} !important;
			color: white !important;
		}
	}

	@media all and (max-width: ${vars.mobileL}) {
		span {
			font-size: 1.2em;
		}
	}
`;
