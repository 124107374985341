import { ChangeEvent, ClipboardEvent, FunctionComponent } from 'react';
import classnames from 'classnames';

import InputWrapper from 'components/layout/forms/InputWrapper';

import StyledComponent from './styles';
import { Props } from './types';

const LayoutFormsTextarea: FunctionComponent<Props> = ({
    className,
    name,
    label,
    placeholder,
    value,
    onChange,
    style,
    styles,
    disabled,
    error,
    decoratorLeft,
    decoratorRight,
}) => {
    return (
        <StyledComponent
            className={classnames(
                'layout-forms-textarea',
                [
                    `style-${style}`,
                    `name-${name}`,
                    className,
                ],
                {
                    'error': Boolean(error),
                    'decorator-left': Boolean(decoratorLeft && decoratorLeft.visible !== false),
                    'decorator-right': Boolean(decoratorRight && decoratorRight.visible !== false),
                }
            )}
            style={styles}
        >
            <InputWrapper
                label={label}
                error={error}
                decoratorLeft={decoratorLeft}
                decoratorRight={decoratorRight}
                disabled={disabled}
            >
                <textarea
                    className="input"
                    name={name}
                    placeholder={placeholder}
                    autoComplete="none"
                    value={value === null ? '' : value}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) => onChange(event)}
                    onPaste={(event: ClipboardEvent<HTMLTextAreaElement>): void => onChange(event)}
                    disabled={disabled}
                />
            </InputWrapper>
        </StyledComponent>
    );
};

export default LayoutFormsTextarea;