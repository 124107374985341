import getConfig from 'next/config';

import { Envs } from 'types/envs';

/* eslint-disable no-console */
export default {
    init (payload = {}): any {
        console.log('Logger init', payload);
        // initRollbar(payload);
    },
    log (data = {}, payload = {}): any {
        this.logConsole('log', data, payload);
    },
    info (data = {}, payload = {}): any {
        this.logConsole('info', data, payload);
        this.logRollbar('info', data, payload);
    },
    debug (data = {}, payload = {}): any {
        this.logConsole('debug', data, payload);
        this.logRollbar('debug', data, payload);
    },
    warning (data = {}, payload = {}): any {
        this.logConsole('error', data, payload);
        this.logRollbar('warning', data, payload);
    },
    error (data = {}, payload = {}): any {
        this.logConsole('error', data, payload);
        this.logRollbar('error', data, payload);
    },
    logConsole (level: string, data = {}, payload = {}): any {
        const { publicRuntimeConfig } = getConfig();

        if (publicRuntimeConfig.APP_ENV === Envs.Development) {
            console[level](data, payload);
        }
    },
    logRollbar (level: string, data = {}, payload = {}): any {
        const { publicRuntimeConfig } = getConfig();

        if (publicRuntimeConfig.APP_ENV !== Envs.Development) {
            console.log('Logger rollbar log', { level, data, payload });
            // rollbar[level](data, payload);
        }
    },
};
