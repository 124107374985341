import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
	position: relative;
	width: 100%;
	height: 100%;

	.hero-wrapper {
		min-height: 100%;
		display: flex;
		place-content: center;
		place-items: center;
		gap: 3em;

		.header {
			.header-button {
				width: 100%;
				.button {
					width: 100%;
				}
				margin: 2em 0 0 0;
			}
		}

		.image-container {
			width: 100%;
			max-width: 40%;
		}
	}

	@media all and (max-width: ${vars.tabletL}) {
		.image-container {
			display: none;
		}
	}
`;
