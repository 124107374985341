import { ChangeEvent, FormEvent, FunctionComponent, useState } from 'react';

import { getErrorsFromRequest } from 'utils/api';

import { create as contactRequest } from 'requests/contact';

import Button from 'components/layout/Button';
import { ButtonTypes } from 'components/layout/Button/types';
import Form from 'components/layout/forms/Form';
import Input from 'components/layout/forms/Input';
import Textarea from 'components/layout/forms/Textarea';

import StyledComponent from './styles';
import { Props } from './types';

interface HandleValue {
    [key:string]: string|number;
}

interface FormState {
    userName: string,
    email: string,
    phone: string,
    content: string,
}

interface Errors extends FormState {};

const ContentSectionsContactForm: FunctionComponent<Props> = ({ translations, onSuccess }) => {
    const [formValues, setFormValues]: [FormState, Function] = useState(null);
    const [errorMessage, setErrorMessage]: [Errors, Function] = useState(null);

    const submitForm = (event:FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        contactRequest({ ...formValues })
            .then(() => onSuccess && onSuccess())
            .catch((errors) => setErrorMessage(getErrorsFromRequest(errors)));
    };

    const handleChange = (value: HandleValue) => setFormValues((prevState:FormState) => ({ ...prevState, ...value }));

    return (
        <StyledComponent className="content-section-contact-form">
            <Form
                onSubmit={submitForm}
                className="contact-form"
            >
                <Input
                    name='userName'
                    label={translations?.inputUserName}
                    placeholder={translations?.inputUserName}
                    onChange={(e:ChangeEvent<HTMLInputElement>) => handleChange({ userName: e.target.value })}
                    value={formValues?.userName}
                    error={errorMessage?.userName}
                />
                <Input
                    name='email'
                    label={translations?.inputEmail}
                    placeholder={translations?.inputEmail}
                    onChange={(e:ChangeEvent<HTMLInputElement>) => handleChange({ email: e.target.value })}
                    value={formValues?.email}
                    error={errorMessage?.email}
                />
                <Input
                    name='phone'
                    label={translations?.inputPhone}
                    placeholder={translations?.inputPhone}
                    onChange={(e:ChangeEvent<HTMLInputElement>) => handleChange({ phone: e.target.value })}
                    value={formValues?.phone}
                    error={errorMessage?.phone}
                />
                <Textarea
                    name='content'
                    label={translations?.inputMessage}
                    placeholder={translations?.placeholderMessage}
                    onChange={(e:ChangeEvent<HTMLTextAreaElement>) => handleChange({ content: e.target.value })}
                    value={formValues?.content}
                    error={errorMessage?.content}
                />
                <div
                    className="rodo"
                    dangerouslySetInnerHTML={{ __html: translations?.rodo }}
                />
                <Button
                    className="submit-button"
                    type={ButtonTypes.Submit}
                >
                    {translations?.submitForm}
                </Button>
            </Form>
        </StyledComponent>
    );
};

export default ContentSectionsContactForm;