import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.header`
	width: 100%;
	position: relative;
	display: flex;
	flex-flow: column wrap;
	align-items: flex-start;

	.header-subtitle {
		color: ${vars.colorTextBlueLighter};
		letter-spacing: 2px;
		font-size: 1.1em;
		text-transform: uppercase;
	}
	.header-title {
		color: ${vars.colorTextWhite};
		line-height: 1.2em;
		font-size: 3em;
		margin: 0.2em 0;
		font-weight: 600;

		p,
		span {
			font-weight: 600;
		}
	}
	.header-description {
		font-size: 1em;
		color: ${vars.colorTextWhite};
		line-height: 1.8em;
	}

	/* styles */
	&.style-hero {
		.header-elements-wrapper {
			background: transparent linear-gradient(90deg, #303030 0%, #303030b3 54%, #30303000 100%) 0% 0%
				no-repeat padding-box;
			padding: 2em;
			border-radius: ${vars.defaultRadius};
		}
		.header-description {
			max-width: 60%;
		}
	}
	&.style-dark {
		padding: 4em 0 0 0;

		.header-title {
			position: relative;
			text-transform: uppercase;
			padding: 0 2em 0.3em 2em;
			border-bottom: 0.05em solid ${vars.colorPrimaryDark};
			letter-spacing: 1px;
			color: ${vars.colorTextDark};

			&:before {
				content: '';
				position: absolute;
				bottom: -4px;
				left: 50%;
				transform: translateX(-50%);
				width: 3em;
				height: 0.1em;
				background-color: ${vars.colorPrimaryGreen};
				border-radius: ${vars.defaultRadius};
				box-shadow: #58ce5293 0px 4px 10px;
			}
		}
		.header-description {
			color: ${vars.colorTextDark};
		}
	}
	&.style-light {
		padding: 4em 0 0 0;

		.header-title {
			position: relative;
			text-transform: uppercase;
			padding: 0 2em 0.3em 2em;
			letter-spacing: 1px;
		}
	}


	/* positions */
	&.text-left {
		align-items: flex-start;
	}
	&.text-center {
		align-items: center;
		text-align: center;
	}


	/* customs */
	.text-green {
		color: ${vars.colorPrimaryGreen};
	}

	@media all and (max-width: ${vars.mobileL}) {
		.header-title {
			font-size: 2.2em;
		}

		.header-description {
			font-size: 1.1em;
		}
	}
`;
