import Component from './component';
import {
    ButtonLayouts,
    ButtonSizes,
    ButtonStyles,
    ButtonTypes,
    ButtonVariants,
} from './types';

Component.defaultProps = {
    style: ButtonStyles.Primary,
    styles: {},
    variant: ButtonVariants.Primary,
    size: ButtonSizes.Medium,
    layout: ButtonLayouts.Default,
    className: '',
    onClick: null,
    type: ButtonTypes.Button,
    disabled: false,
    loading: false,
    buttonProps: {},
    icon: null,
};

export default Component;