import { FunctionComponent } from 'react';

import PlatformSlider from 'components/content/sections/PlatformSlider';
import Header from 'components/layout/Header';
import { HeaderStyles, HeaderTextPositions } from 'components/layout/Header/types';
import LayoutContainer from 'components/layout/LayoutContainer/component';

import StyledComponent from './styles';
import { Props } from './types';

const PageHomeSectionPlatform: FunctionComponent<Props> = ({ translations }) => {
    return (
        <StyledComponent className="page-home-section-platform">
            <LayoutContainer className="platform">
                <Header
                    className="header-platorm"
                    title={translations?.title}
                    description={translations?.description}
                    style={HeaderStyles.Dark}
                    textPosition={HeaderTextPositions.Center}
                />
            </LayoutContainer>
            <div className='platform-slider-wrapper'>
                <LayoutContainer>
                    <PlatformSlider />
                </LayoutContainer>
            </div>
        </StyledComponent>
    );
};

export default PageHomeSectionPlatform;