import { FunctionComponent, useState } from 'react';

import ContactForm from 'components/content/sections/forms/ContactForm';
import FormResultInfo from 'components/content/sections/forms/FormResultInfo';
import Header from 'components/layout/Header';
import { HeaderStyles, HeaderTextPositions } from 'components/layout/Header/types';
import LayoutContainer from 'components/layout/LayoutContainer/component';

import StyledComponent from './styles';
import { Props } from './types';

enum States {
    ContactForm = 'form',
    FormResultInfo = 'success',
}

const PageHomeSectionContact: FunctionComponent<Props> = ({ translations }) => {
    const [state, setState]: [States, Function] = useState(States.ContactForm);

    return (
        <StyledComponent
            className="page-home-section-contact"
            id="contact"
        >
            <LayoutContainer className="contact">
                <Header
                    title={translations?.title}
                    style={HeaderStyles.Dark}
                    textPosition={HeaderTextPositions.Center}
                />
                <div className="contact-cols-wrapper">
                    <div className="col-left">
                        <h3
                            className="col-left-headline"
                            dangerouslySetInnerHTML={{ __html: translations?.leftInfo }}
                        />
                    </div>
                    <div className="col-right">
                        { state === States.ContactForm
                            ? (
                                <ContactForm
                                    onSuccess={() => setState(States.FormResultInfo)}
                                    translations={{
                                        inputUserName: translations?.inputUserName,
                                        inputEmail: translations?.inputEmail,
                                        inputPhone: translations?.inputPhone,
                                        inputMessage: translations?.inputMessage,
                                        placeholderMessage: translations?.placeholderMessage,
                                        submitForm: translations?.submitForm,
                                        rodo: translations?.rodo,
                                    }}
                                />
                            )
                            : (
                                <FormResultInfo
                                    title={translations?.resultFormTitle}
                                    description={translations?.resultFormDescription}
                                />
                            )
                        }
                    </div>
                </div>
            </LayoutContainer>
        </StyledComponent>
    );
};

export default PageHomeSectionContact;