import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
	width: 100%;

	.contact-form {
		display: flex;
		flex-flow: column wrap;
		gap: 1em;

        .rodo {
            font-size: 0.8em;

            .link {
                color: #000000;

                &:hover {
                    color: #949494;
                }
            }
        }

		.submit-button {
			.button {
				padding: 1em 6em;
			}
		}
	}
`;
