import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
	position: relative;
	width: 100%;
	height: 100%;
	margin: 4em 0;
	display: flex;
	flex-flow: column wrap;
	place-content: center;
	

	@media all and (max-width: ${vars.mobileL}) {

	}
`;
