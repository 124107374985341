import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    .form-error-message {
        font-size: 1.4em;
        color: red;
        font-weight: 300;
        text-align: center;
    }

    form {
        > .layout-button {
            margin-top: .5em;
        }
    }
`;
