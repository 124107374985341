import { parseToQueryString } from 'utils/querystring';

export function shorten(string: string, length: number, addDots = false): string {
    if (!string || typeof string !== 'string') {
        return '';
    }

    let newString = string.substring(0, length);
    if (string.length > length && addDots) newString = newString + '...';

    return newString;
}

export function withVariables(string: string, variables = {}, queryObject = {}, hash = ''): string {
    if (!string || typeof string !== 'string') {
        return '';
    }

    Object.keys(variables)
        .forEach(variableKey => {
            const pattern = ':variableKey'.replace('variableKey', variableKey);

            string = string.replace(pattern, variables[variableKey]);
        });

    Object.keys(variables)
        .forEach(variableKey => {
            const pattern = '[variableKey]'.replace('variableKey', variableKey);

            string = string.replace(pattern, variables[variableKey]);
        });

    string = string
        .replace('(', '')
        .replace(')', '');

    return parseToQueryString(string, queryObject) + hash;
}