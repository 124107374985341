import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	display: flex;
	flex-flow: column wrap;
	place-items: center;

	.graph-element-content {
		padding: 1em 0 1em 0;
		text-align: center;

		.grap-element-name {
			font-weight: 600;
			text-transform: uppercase;
			font-size: 1.2em;
		}
	}
`;
