export enum TileTypes {
    Title = 'title',
    Price = 'price',
    SubPrice = 'subPrice',
    IconBlue = 'iconBlue',
    IconGreen = 'iconGreen',
}

export interface Tile {
    name: string;
    type: string;
    active?: boolean;
}

export interface Props {
    listElements: Tile[];
    style: string;
}
