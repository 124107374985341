import { FunctionComponent } from 'react';
import classNames from 'classnames';

import StyledComponent from './styles';
import { Props } from './types';

const LayoutFormsForm: FunctionComponent<Props> = ({ children, onSubmit, className }) => {
    return (
        <StyledComponent className="layout-forms-form">
            <form
                onSubmit={onSubmit}
                className={classNames('layout-forms-form', className)}
                autoComplete="off"
            >
                {children}

                {/* Hack for chrome autocomplete: https://stackoverflow.com/questions/37503656/react-doesnt-render-autocomplete-off */}
                <input
                    type="text"
                    autoComplete="on"
                    value=""
                    style={{ display: 'none', opacity: 0, position: 'absolute', left: '-100000px' }}
                    onChange={() => null}
                />
            </form>
        </StyledComponent>
    );
};

export default LayoutFormsForm;