import { FunctionComponent } from 'react';

import GraphMiddleImage from 'theme/images/solution/graph-middle.png';
import GraphTopImage from 'theme/images/solution/graph-top.png';

import GraphElement from 'components/content/blocks/GraphElement';

import StyledComponent from './styles';
import { Props } from './types';

const ContentSectionsSolutionGraphs: FunctionComponent<Props> = ({ graphElements }) => {
    const setImage = (idx: number) => {
        switch (idx) {
            case 0:
                return GraphTopImage;
            default:
                return GraphMiddleImage;
        }
    };

    return (
        <StyledComponent className="content-sections-contact-titles">
            <div className="tiles-container">
                { Array.isArray(graphElements) && graphElements?.map((element, idx) => (
                    <GraphElement
                        key={element?.name || idx}
                        image={setImage(idx)}
                        {...element}
                    />
                ))}
            </div>
        </StyledComponent>
    );
};

export default ContentSectionsSolutionGraphs;