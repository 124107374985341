import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
	position: relative;
	width: 100%;
	min-height: 80vh;
	overflow: hidden;
	background: url('/images/hero/hero-slide-1.png');
	background-size: cover;
	display: flex;
	place-content: center;
	place-items: center;

	@media all and (max-width: ${vars.mobileL}) {
	}
`;
