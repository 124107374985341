const translations = {
    title: {
        type: 'text',
        defaultValue: 'Rozwiązanie',
    },
    elements: {
        type: 'array',
        arraySchema: {
            name: { type: 'text' },
            description: { type: 'text' },
        },
        defaultValue: [{
            name: 'Lepsza komunikacja',
            description: 'Proste rozwiązania dla lepszego planowania pracy!',
        }, {
            name: 'Lepsza dokumentacja',
            description: 'Wystawianie faktur, generowanie raportów oraz połączenie z BDO!',
        }, {
            name: 'Lepsze śledzenie',
            description: 'Intuicyjne podejście do korzystania z narzędzia!',
        }],
    },
};

export default translations;
