export enum IconTypes {
    IconBlue = 'iconBlue',
    IconGreen = 'iconGreen',
}

export interface Props {
    text: string;
    type?: IconTypes;
    active?: boolean;
    className?: string;
}
